<template>
  <q-card class="bg-grey-1">
    <q-card-section>
      <h4
        class="text-h6 text-weight-bold q-mb-none q-mt-none flex justify-between"
      >
        Credentials

        <q-btn
          round
          dense
          flat
          color="primary"
          icon="mdi-plus-circle"
          @click="addCredentialItem"
        />
      </h4>

      <q-form
        v-if="localCredentials.length > 0"
        @submit.prevent="onSubmitForm"
        class="q-mt-md"
      >
        <div class="row">
          <section class="col-5 text-subtitle1 text-weight-bold">Key</section>

          <section class="col-7 text-subtitle1 text-weight-bold q-pl-md">
            Value
          </section>
        </div>

        <div class="row">
          <aside
            v-for="(credential, credentialIndex) in localCredentials"
            :key="credentialIndex"
            class="row col-12"
          >
            <section class="col-12">
              <q-btn
                round
                dense
                flat
                color="negative"
                icon="mdi-minus-circle"
                class="flex q-ml-auto q-mb-sm"
                @click="deleteCredentialItem(credentialIndex)"
              />
            </section>

            <section class="col-5 text-subtitle1 text-weight-bold">
              <q-input
                v-model="credential.key"
                :disable="!accessEditCredentials"
                :rules="[fieldRules.required]"
                outlined
                dense
              />
            </section>
            <section class="col-7 text-subtitle1 text-weight-bold q-pl-md">
              <q-input
                v-model.trim="credential.value"
                :rules="[fieldRules.required]"
                :disable="!accessEditCredentials"
                outlined
                dense
              />
            </section>
          </aside>
        </div>

        <q-btn
          v-if="accessEditCredentials"
          :loading="loading"
          unelevated
          color="primary"
          type="submit"
          class="flex q-mt-md q-ml-auto text-weight-bold"
        >
          Save
        </q-btn>
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script>
import { accountController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils, cloneStructured, notify } from '@/shared/utils';

export default {
  props: {
    accountId: {
      type: Number,
      required: true,
    },
    credentials: {
      type: Array,
      required: true,
    },
  },
  data() {
    const localCredentials = Array.isArray(this.credentials)
      ? cloneStructured(this.credentials)
      : [];

    return {
      loading: false,
      localCredentials,
    };
  },
  computed: {
    fieldRules() {
      return {
        required: (value) =>
          !!value?.toString().trim().length || 'Please type value',
      };
    },
    accessEditCredentials() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_AGENT,
      ]);
    },
  },
  methods: {
    addCredentialItem() {
      this.localCredentials.push({
        key: '',
        value: '',
      });
    },
    deleteCredentialItem(index) {
      this.localCredentials.splice(index, 1);
    },
    async onSubmitForm() {
      if (this.loading || !this.accessEditCredentials) return;

      this.loading = true;

      try {
        const { credentials } =
          await accountController.updateRefillAccountCredentials({
            id: this.accountId,
            credentials: this.localCredentials,
          });

        this.loading = false;

        this.$emit('update:credentials', credentials);
        notify.success('Account credentials saved');
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
